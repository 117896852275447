<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/6
  * @BSD
-->
<template>
  <div class="page-wrap">
    <div class="main">
      <div class="main-content">
        <img class="line1" src="../../assets/login_img_title.png" alt="">
        <img class="line2" src="../../assets/login_img_fubiaoti.png" alt="">
        <div class="line3 line-form">
          <div class="label">用户名</div>
          <van-field v-model="userName"/>
        </div>
        <div class="line4 line-form">
          <div class="line3">
            <div class="label">密码</div>
            <van-field v-model="userName" type="password"/>
          </div>
        </div>
        <div class="line5">
          <div class="icon active"></div>
          <div class="text1">注册即同意</div>
          <div class="text2">《用户协议条款》</div>
        </div>
        <div class="line6">
          <div class="btn-save">
            <div class="btn-content">注册/登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data () {
    return {
      userName: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "./login";
</style>
